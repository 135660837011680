<template>
    <div>
        <div class="container-lg mt-3 mt-md-5 mb-3 mb-md-5">
            <div v-if="wait" class="posts-empty mt-3">
                <div class="posts-empty-inner">
                    <h4>Please wait...</h4>
                    <h6>Loading survey details, please wait...</h6>
                </div>
            </div>
        </div>

        <div v-if="survey" class="container-lg mt-3 mt-md-5 mb-3 mb-md-5">
            <div class="preview-title">
                <h1>{{ survey.title }}</h1>
            </div>

            <div class="preview">
                <div class="survey-desc">
                    <p v-if="survey.description" class="text-secondary mb-3">{{ survey.description }}</p>
                    <b>Survey End Date: </b> {{ moment(survey.end_date).format("MMM D, YYYY HH:mm") }}
                </div>
                <br>
                <Questionnaire :respond="true" :survey="survey"></Questionnaire>
            </div>
        </div>
    </div>
</template>

<script>
import Questionnaire from "../Helpers/Questionnaire";

export default {
    name: "ViewSurvey",
    props: ['code'],
    components: {Questionnaire},
    data() {
        return {
            survey: null,
        }
    },
    mounted() {
        this.wait = true
        this.$http.get('/api/surveys/view/' + this.code).then(res => {
            this.survey = res.data.data
        }).catch(error => {
            this.showError(error)
        }).then(() => {
            this.wait = false
        })
    }
}
</script>

<style scoped>
</style>